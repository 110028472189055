import React from 'react'
import { Badge, Flex, Image, Text } from '@chakra-ui/react'

export default ({
    items,
    handleSelectItem,
}: {
    items: any[]
    handleSelectItem: Function
}) => {
    return (
        <Flex
            gap={4}
            w={'full'}
            flexWrap={'wrap'}
            cursor={'pointer'}
            height={'fit-content'}
        >
            {items.map((item) => (
                <Flex
                    direction={'column'}
                    w={'200px'}
                    alignItems={'flex-start'}
                    onClick={() => handleSelectItem(item)}
                >
                    <Image src={item.image} height={'100px'} mx={'auto'} />
                    <Text mx={'auto'}>{item.name}</Text>
                    {!!item.price && (
                        <Badge mx={'auto'}>{item.price} ден.</Badge>
                    )}
                </Flex>
            ))}
        </Flex>
    )
}
