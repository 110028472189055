import { configureStore } from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import locationSlice from "./reducers/locations";
import ordersSlice from "./reducers/orders";
import orderFormSlice from "./containers/OrderForm/orderFormSlice";
import profileSlice from "./containers/ProfilePage/profileSlice";
import registerSlice from "./components/RegistrationSteps/registrationSlice";

const store = configureStore({
    reducer: {
        locations: locationSlice,
        orders: ordersSlice,
        orderForm: orderFormSlice,
        profile: profileSlice,
        register: registerSlice
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store