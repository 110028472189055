import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Heading,
    Image,
    Text,
} from '@chakra-ui/react'
import { Controller, useFormContext } from 'react-hook-form'

export default ({
    text,
    iconPath,
    value,
    handleClick,
}: {
    text: string
    iconPath: string
    value: string
    handleClick: Function
}) => {
    const form = useFormContext()
    const fieldValue = form.watch('orderPlace')
    return (
        <Controller
            render={({ field: { onChange, onBlur, ref } }) => (
                <Card
                    cursor={'pointer'}
                    mb={'30px'}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    onClick={() => {
                        form.setValue('orderPlace', value)
                        handleClick()
                    }}
                >
                    <CardHeader>
                        <Image src={iconPath} height={'100px'} />
                    </CardHeader>
                    <CardFooter>
                        <Heading size="md" textAlign={'center'}>
                            {text}
                        </Heading>
                    </CardFooter>
                </Card>
            )}
            name={'orderPlace'}
        />
    )
}
