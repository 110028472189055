import {
    Box,
    Button,
    Collapse,
    Flex, FormControl, FormErrorMessage, FormLabel, HStack, Image, Input, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, PinInput, PinInputField, Spinner,
    Text,
    useTimeout
} from "@chakra-ui/react";
import OrderCard from "../../components/OrderCard/OrderCard";
import React, {useEffect, useRef, useState} from "react";
import {Icon} from "@chakra-ui/icons";
import {FaFilePdf} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import {useReactToPrint} from "react-to-print";
import Hourglass from "../../components/Hourglass/Hourglass";
import {ORDER_STATUS} from "../OrderForm/orderFormSlice";
import {Controller} from "react-hook-form";
import {Rating} from "react-simple-star-rating";

export default ({order, handleLeaveComment}: {order: null|any, handleLeaveComment: Function}) => {
    const [orderData, setOrderData] = useState(order as null | any);
    const [orderReadyIn, setOrderReadyIn] = useState(order?.readyInMinutes || 20)
    const [ratingModalOpen, setRatingModalOpen] = useState(false);
    const [rating, setRating] = useState(-1);
    const [ratingComment, setRatingComment] = useState('')
    const {t} = useTranslation();
    const componentRef= useRef<HTMLDivElement>(null)
    const timeoutRef = useRef(null as null | any);
    const handleRating = (rate: number) => {
        setRating(rate)

        // other logic
    }
    const onPointerEnter = () => console.log('Enter')
    const onPointerLeave = () => console.log('Leave')
    const onPointerMove = (value: number, index: number) => console.log(value, index)
    useEffect(() => {
        if(order) {
            setOrderData(order)
            setOrderReadyIn(order?.readyInMinutes || orderReadyIn ||  20)
            if(![ORDER_STATUS.REJECTED, ORDER_STATUS.COMPLETED].includes(order.orderStatus)) {
                timeoutRef.current = setTimeout(() => {
                    window.location.reload();
                }, 10000);
                console.log("Timeout set");
            } else {
                console.log("Timeout cleared");
                clearTimeout(timeoutRef.current)
            }
            if(order.orderStatus === ORDER_STATUS.COMPLETED && !order.userRating?.rating) {
                setRatingModalOpen(true)
            }
        }
    }, [order])
    useEffect(() => {
        setTimeout(() => {
            if(orderReadyIn - 1 <= 2) {
                setOrderReadyIn(3)
            } else {
                setOrderReadyIn(orderReadyIn - 1)
            }
        }, 60000)
    }, [orderReadyIn])
    const handlePrint = useReactToPrint({
        content: () => componentRef?.current
    })

    return <Flex flexDirection={'column'} w={'full'} pt={10}>
        {!!orderData?.orderId && <Flex justifyContent={'flex-end'} mb={3}>
            <Text>{t('downloadOrder')} <Icon as={FaFilePdf} fontSize={20} onClick={handlePrint}/></Text>
        </Flex>}

        {!!orderData?.orderId && <Flex ref={componentRef} p={10}><OrderCard order={order}/></Flex>}
        {!!orderData?.orderId && [ORDER_STATUS.IN_PROGRESS, ORDER_STATUS.ACCEPTED].includes(orderData?.orderStatus) && <Flex my={10} justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
            <Hourglass/>
            <Text mt={10}>{t('orderReadyIn')} {orderReadyIn} {t('minutes')}</Text>
        </Flex>}
        {orderData?.orderStatus === ORDER_STATUS.COMPLETED && !orderData.userRating?.rating && <Modal onClose={() => setRatingModalOpen(false)} isOpen={ratingModalOpen} isCentered size={{lg: 'md', base: 'full'}} motionPreset={'slideInBottom'}>
            <ModalOverlay />
            <ModalContent>


                <ModalHeader>{t('rateUs')}</ModalHeader>
                <ModalCloseButton />
                    <ModalBody>
                        <Flex direction={'column'}>
                            {t('leaveRating')}
                            <Input
                                onChange={(e) => setRatingComment(e.target.value)}
                                value={
                                ratingComment
                                }
                                maxLength={180}
                            />

                        </Flex>
                        <Flex justifyContent={'center'} mt={10}>
                            <Rating
                                SVGstyle={{ display: 'inline-block' }}
                                onClick={handleRating}
                                onPointerEnter={onPointerEnter}
                                onPointerLeave={onPointerLeave}
                                onPointerMove={onPointerMove}
                                /* Available Props */
                            />
                        </Flex>

                    </ModalBody>
                    <ModalFooter>
                        <Flex w={'100%'} justifyContent={'space-between'}>
                            <Button mb={10} type={'submit'} color='brand.text' onClick={()=>{setRatingModalOpen(false)}}>{t('cancelRating')}</Button>
                            <Button mb={10} type={'submit'} bgColor='brand.primary' color='brand.text' isDisabled={rating < 1} onClick={() => {handleLeaveComment(rating, ratingComment); setRatingModalOpen(false)}}>{t('submitRating')}</Button>
                        </Flex>



                    </ModalFooter>


            </ModalContent>
        </Modal>}

    </Flex>
}