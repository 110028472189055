import authApi from "../services/authApi";

export interface IRegisterBody {
    email: string,
    confirmEmail: string,
    displayName: string,
    phone: string,
    password: string,
    confirmPassword: string,
}

export interface IActivateBody {
    phone: string,
    password: string,
    OTP: string
}

export interface IResendSMSBody {
    phone: string
}
export const registerUser = (body:IRegisterBody) => {
    return authApi.post('/user/register/app', body)
}
export const activateUser = (body: IActivateBody) => {
    return authApi.post('/user/activate/app', body)
}

export const resendSMS = (body: IResendSMSBody) => {
    return authApi.post('/user/resend/app', body)
}

export const getProfile = () => {
    return authApi.get('/user/myself')
}

export const updateDisplayName = (displayName: string) => {
    return authApi.post('/user/myself/display-name', {
        displayName
    })
}

export const updatePassword = (password: string) => {
    return authApi.post('/user/myself/password', {
        password
    })
}

export const registerAppUser = (phone: string, displayName: string) => {
    return authApi.post('/auth/intent', {
        phone, displayName
    })
}

export const loginAppUser = (phone: string, password: string) => {
    return authApi.post('/auth/verify', {
        phone, password
    })
}


