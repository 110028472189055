import {LANGUAGES} from "../components/Footer/Footer";
import api from "./api";

export const logoutUser = () => {
    localStorage.removeItem('token')
}

export const loginUser = (token: string) => {
    localStorage.setItem('token', token)
}

export const isAuth = () => {
    return localStorage.getItem('token')
}

export const getAccount = () => {
    return JSON.parse(localStorage.getItem('account') || '{}');
}

export const loginUserRequest = (authBody: {login: string, password: string}) => {
    return api.post('/auth/login', {
        ...authBody
    })
}

export const setAccount = (account: any) => {
    return localStorage.setItem('account', JSON.stringify(account))
}

export const getLanguage = () => {
    return localStorage.getItem('language') || LANGUAGES.EN;
}

export const setLanguage = (lang: string) => {
    return localStorage.setItem('language', lang)
}
