import {Button, Card, CardBody, CardFooter, CardHeader, Heading, Text} from "@chakra-ui/react";

export default ({location, callbackClick}: {location: any, callbackClick: Function}) => {
    return <Card cursor={'pointer'} onClick={() => callbackClick(location)}  height={'150px'} width={'300px'} mb={'30px'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <CardHeader>
            <Heading size='md' textAlign={'center'}>{location.name}</Heading>
        </CardHeader>
        <CardFooter>
            <Text>{location.phone}</Text>
        </CardFooter>
    </Card>
}