import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {ChakraProvider, Flex} from '@chakra-ui/react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Homepage} from "./containers/Homepage/Homepage";
import {Provider} from "react-redux";
import store from "./store";
import MenuPage from "./containers/MenuPage/MenuPage";
import EatingLocation from "./containers/EatingLocation/EatingLocation";
import OrderOverview from "./containers/OrderOverview/OrderOverview";
import OrderInfo from "./containers/OrderInfo/OrderInfo";
import {OrderComplete} from "./containers/OrderComplete/OrderComplete";
import './i18n';
import MenuBar from "./components/MenuBar/MenuBar";
import UserOrders from "./containers/UserOrders/UserOrders";
import ProfilePage from "./containers/ProfilePage/ProfilePage";
import theme from "./theme";
import bgImage from './bg.jpg';
import Footer from "./components/Footer/Footer";
import OrderForm from "./containers/OrderForm/OrderForm";
import OrderDetails from "./containers/OrderDetails/OrderDetails";

const router = createBrowserRouter([
    {
        path: '/profile',
        element: <ProfilePage/>
    },
    {
        path: '/orders',
        element: <UserOrders/>
    },
    {
        path: '/orders/:id',
        element: <OrderDetails/>
    },
    {
        path: ':id/menu',
        element: <OrderForm/>
    },

    // {
    //     path: ':id/menu',
    //     element: <OrderForm/>
    // },
    // {
    //     path: ':id/order-overview',
    //     element: <OrderOverview/>
    // },
    // {
    //     path: ':id/order-info',
    //     element: <OrderInfo/>
    // },
    // {
    //     path: ':id/order-complete',
    //     element: <OrderComplete/>
    // },
    {
        path: "/:id?",
        element: <Homepage/>,
    }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <ChakraProvider theme={theme}>
          <Provider store={store}>
              <Flex direction={'column'} h={'100vh'}/*bgImage={bgImage}*/>
                  <MenuBar/>
                  <Flex maxW={'700px'} mx={'auto'} w={'100%'}>
                      <RouterProvider router={router} />
                  </Flex>
                  <Footer/>
              </Flex>

          </Provider>
      </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
