import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createOrder } from '../../repository/orders'

export interface IProduct {
    productId?: string
    name: string
    productType: string
    description: string
    image: string
    price: number
    imageSelected?: File
    taxRate: string
    maxNumberOfMeats: number
    maxNumberOfSauces: number
}

export enum ORDER_STATUS {
    CREATED = 'CREATED',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    IN_PROGRESS = 'IN_PROGRESS',
    READY_FOR_PICKUP = 'READY_FOR_PICKUP',
    OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
    COMPLETED = 'COMPLETED',
    HIDDEN = 'HIDDEN',
}
export interface IOrderItem {
    item: IProduct | null
    extras: IProduct[]
    id?: string
    quantity: number
    comment?: string
}
export interface IOrder {
    items: IOrderItem[]
    status: string
    id?: string
}
export interface IOrderForm {
    isLoading: boolean
    data: IOrder
    error: boolean
    isVisible: boolean
    selectedProduct: null | any
}

const initialState: IOrderForm = {
    isLoading: false,
    data: {
        status: 'DRAFT',
        items: [],
    },
    isVisible: true,
    error: false,
    selectedProduct: null,
}

export const createNewOrder = createAsyncThunk(
    'orders/createNewOrder',
    async (order: any, thunkAPI) => {
        const response = await createOrder(order);
        return response.data
    }
)
export const orderFormSlice = createSlice({
    name: 'orderForm',
    initialState,
    reducers: {
        setOrder: (state, action) => {
            state.data = action.payload
            state.isVisible = true
        },
        setSelectedItem: (state, action) => {
            state.selectedProduct = action.payload
        },
        cancelAction: (state) => initialState,
        completeAction: (
            state,
            action: PayloadAction<{
                id: string
                address: string
                contact: string
            }>
        ) => initialState,
    },
    extraReducers: (builder) =>
        builder
            .addCase(createNewOrder.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(createNewOrder.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                state.isVisible = false
            })
            .addCase(createNewOrder.rejected, (state, action) => {
                state.isLoading = false
                state.error = false
            }),
})

export const { setOrder, completeAction, cancelAction, setSelectedItem } =
    orderFormSlice.actions
export default orderFormSlice.reducer
