import HomepageView from "./HomepageView";
import locations, {fetchAllLocations, selectLocation} from "../../reducers/locations";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {RootState} from "../../store";
import {useNavigate, useParams} from "react-router-dom";

export const Homepage = () => {
    const dispatch = useAppDispatch();
    let { id } = useParams();
    const locationsState = useAppSelector((state: RootState) => state.locations);
    const navigate = useNavigate();
    const handleLocationClick = (location:any) => {
        dispatch(selectLocation(location));
        navigate(`/${location.locationId}/menu`)
    }
    useEffect(() => {
        dispatch(fetchAllLocations())
    }, [])
    useEffect(() => {
        if(id && locationsState.data?.length) {
            const foundLocation = locationsState.data.find(location => location.locationId === id);
            if(foundLocation) {
                console.log("here");
                handleLocationClick(foundLocation);
            }
        }
    }, [locationsState.data])
    return <HomepageView locations={locationsState.data} handleLocationClick={handleLocationClick}/>
}