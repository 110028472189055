import OrdersView from "./UserOrdersView";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {RootState} from "../../store";
import {useEffect} from "react";
import {fetchAllOrders} from "../../reducers/orders";

export default () => {
    const dispatch = useAppDispatch();
    const orders = useAppSelector((state: RootState) => state.orders);
    useEffect(() => {
        dispatch(fetchAllOrders());
    }, [])
    return <OrdersView orders={orders.data}/>
}