import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {createOrderCall, getLocations} from "../repository/location";
import {getUserOrders} from "../repository/orders";

interface ILocationSlice {
    isLoading: boolean
    data: any[]
    error: boolean
}

export const fetchAllOrders = createAsyncThunk(
    'locations/fetchAllLocations',
    async (arg, thunkAPI) => {
        const response = await getUserOrders()
        return response.data?.orders;
    }
)


const initialState: ILocationSlice = {
    isLoading: false,
    data: [],
    error: false,
}
const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(fetchAllOrders.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(fetchAllOrders.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                state.data = action.payload
            })
            .addCase(fetchAllOrders.rejected, (state, action) => {
                state.data = []
                state.error = true
            }),
})


export default ordersSlice.reducer
