import { IOrderItem } from '../containers/OrderForm/orderFormSlice'
import { ProductType } from '../containers/OrderForm/components/MenuPage/MenuPage'

export const calculateTotalPrice = (items: IOrderItem[]): number => {
    return items.reduce((acc, item) => {
        const itemPrice = item?.item?.price ? +item?.item?.price : 0
        const isCombo = item.item?.productType === ProductType.COMBO
        const extrasPrice = item.extras.reduce(
            (acc, currentExtra) =>
                acc +
                (isCombo && currentExtra.productType === ProductType.DRINK
                    ? 0
                    : +currentExtra.price),
            0
        )
        return acc + (itemPrice + extrasPrice) * item.quantity
    }, 0)
}
