import {Badge, Card, CardBody, CardFooter, CardHeader, Divider, Flex, Image, Tag, Text} from "@chakra-ui/react";
import {Icon} from "@chakra-ui/icons";
import {FaClock, FaTag} from "react-icons/fa";
import dayjs from "dayjs";
import React from "react";
import {ORDER_STATUS} from "../../containers/OrderForm/orderFormSlice";
import {useTranslation} from "react-i18next";

export default ({order}: {order:any}) => {
    const {t} = useTranslation();
    const renderStatusTag = (orderStatus: string) => {
        let defaultOrderStatusColor = 'yellow';
        switch(orderStatus) {
            case ORDER_STATUS.CREATED:
                defaultOrderStatusColor = 'orange'
                break;
            case ORDER_STATUS.ACCEPTED:
                defaultOrderStatusColor = 'green'
                break;
            case ORDER_STATUS.IN_PROGRESS:
                defaultOrderStatusColor = 'green'
                break;
            case ORDER_STATUS.READY_FOR_PICKUP:
                defaultOrderStatusColor = 'purple'
                break;
            case ORDER_STATUS.COMPLETED:
                defaultOrderStatusColor = 'yellow'
                break;
            case ORDER_STATUS.REJECTED:
            default:
                defaultOrderStatusColor = 'red'
                break;
        }
        return  <Badge borderRadius='full' colorScheme={defaultOrderStatusColor}
                     variant='solid'>{t(orderStatus)}</Badge>
    }
    return <Card w={'full'}>
        <CardHeader  p={5}>
            <Flex pb={5}>
                <Flex mr={10}>
                    <Image src={'/images/bon-tacos.jpeg'} height={'80px'} width={'80px'} mx={'auto'}/>
                </Flex>
                <Flex direction={'column'}>
                    <Text><Icon as={FaTag} mr={3}/>{order.orderId?.split('-').pop()}</Text>
                    <Text><Icon as={FaClock} mr={3}/>{dayjs(order.createdAt).format('DD.MM.YYYY - hh:mm')}</Text>
                    <Text></Text>
                </Flex>
            </Flex>

            <Divider/>
        </CardHeader>
        <CardBody  pt={0}>
            <Flex direction={'column'}>
                {order.items.map((orderItem:any) => <Flex direction={'column'} mb={2}>
                    <Text>{orderItem.quantity + 'x ' + orderItem.item.name}</Text>
                    {orderItem.extras.map((extra:any) => <><Text>{'- ' + extra.name}</Text></>)}
                </Flex>) }
            </Flex>
            <Divider/>
        </CardBody>
        <CardFooter>
            <Flex w={'full'} justifyContent={'space-between'}>
                <Flex h={'max-content'}>
                    {renderStatusTag(order.orderStatus)}
                </Flex>
                <Flex direction={'column'}>
                    <Text as={'h3'}><b>Total:</b></Text>
                    <Text as={'h2'} color={'green'}><b>{order.totalPrice}MKD</b></Text>
                </Flex>
            </Flex>
        </CardFooter>
    </Card>
}