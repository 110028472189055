import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {activateUser, IActivateBody, IRegisterBody, registerUser} from "../../repository/user";


interface IRegistrationSlice {
    isLoading: boolean
    error: boolean
}

export const createAppUserAction = createAsyncThunk(
    'registration/createAppUser',
    async (body: IRegisterBody, thunkAPI) => {
        const response = await registerUser(body);
        return response.data
    }
)

export const activateAppUserAction = createAsyncThunk(
    'registration/activateAppUser',
    async (body: IActivateBody, thunkAPI) => {
        const response = await activateUser(body);
        return response.data
    }
)



const initialState: IRegistrationSlice = {
    isLoading: false,
    error: false,
}
const locationsSlice = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        resetState: (state) => initialState,
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(createAppUserAction.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(createAppUserAction.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
            })
            .addCase(createAppUserAction.rejected, (state, action) => {
                state.isLoading = false
                state.error = true
            }),
})

export const { resetState, startLoading, stopLoading, } = locationsSlice.actions

export default locationsSlice.reducer
