import API from '../services/api'
import AuthAPI from '../services/authApi'
import { IOrder, ORDER_STATUS } from '../containers/OrderForm/orderFormSlice'

export const getUserOrders = async () => {
    return await AuthAPI.get('/order/my-orders');
}



export const getOrders = async (
    locationId: string,
    orderId: string = '',
    createdAt: number | string = '',
    currentDayOnly: boolean = true
) => {
    return await API.get(
        `/order?locationId=${locationId}&createdAt=${createdAt}&orderId=${orderId}&pageSize=20&currentDayOnly=${currentDayOnly}`
    )
}

export const setOrderStatus = async (id: string, orderStatus: ORDER_STATUS) => {
    return await API.put(`/order/${id}`, {
        orderStatus,
    })
}

export const setOrderDiscount = async (id: string, discount: number) => {
    return await API.post(`/order/${id}/discount`, {
        discount,
    })
}

export const createOrder = async (data: any) => {
    return await AuthAPI.post('/order/public', data)
}

export const getOrderById = async (orderId: string) => {
    return await API.get(`/order/${orderId}`)
}

export const leaveOrderComment = async(orderId: string, rating: number, comment: string)=> {
    return await AuthAPI.put(`/order/${orderId}/comment`, {rating, comment})
}