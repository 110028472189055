import {useFormContext } from 'react-hook-form'
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    useToast,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import {getAccount, isAuth} from "../../../../services/auth";
import React from "react";

export default () => {
    const account = getAccount()
    const {
        register,
        formState: { errors },
        getValues
    } = useFormContext()
    const navigate = useNavigate()
    const handleCancelOrder = () => navigate('/')
    console.log(getValues())
    return (
        <Flex direction={'column'}>
            <Input {...register("userId")} defaultValue={account ? account.userId : ''} type={'hidden'}/>
            <FormControl isInvalid={!!errors.orderName}>
                <FormLabel>Име:</FormLabel>
                <Input defaultValue={account.displayName || ''} disabled={!!isAuth()} {...register('orderName', { required: true })} />
                {!!errors.orderName && (
                    <FormErrorMessage>Името е задолжително</FormErrorMessage>
                )}
            </FormControl>
            <FormControl isInvalid={!!errors.phoneNumber}>
                <FormLabel>Телефонски број за контакт:</FormLabel>
                <Input  defaultValue={!!account ? account.phone : ''} disabled={!!isAuth()}
                       {...register('phoneNumber', {
                        pattern: {
                            value: /^\+\d{11}$/,
                            message: 'Грешен формат на телефонски број',
                        },
                    })}
                />
                <FormHelperText>
                    Форматот мора да биде: +38911222333
                </FormHelperText>
                {!!errors.phoneNumber && (
                    <FormErrorMessage>
                        {errors?.phoneNumber?.message?.toString() || ''}
                    </FormErrorMessage>
                )}
            </FormControl>

            <Button bgColor={'brand.primary'} type={'submit'} mt={5}>
                Креирај нарачка
            </Button>
            <Button mt={5} onClick={() => handleCancelOrder()}>
                Почни одново
            </Button>
        </Flex>
    )
}
