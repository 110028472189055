import {Flex, Text, Image, Heading} from "@chakra-ui/react";
import LocationCard from "../../components/LocationCard/LocationCard";
import React, {useState} from "react";
import RegistrationSteps from "../../components/RegistrationSteps/RegistrationSteps";
import {useTranslation} from "react-i18next";

export default ({locations, handleLocationClick}: {locations: any[], handleLocationClick: Function}) => {
    const {t} = useTranslation()
    return <Flex className={'w-full'} justifyContent={'center'} flexDirection={'column'} max-w={'50%'} mx={'auto'}>
        <Image src={'/images/Address-amico.svg'} height={'150px'} width={'150px'} mx={'auto'} mt={'30px'}  mb={16} />
        <Heading as={'h1'} textAlign={'center'}  mb={32} >{t('chooseLocation')}</Heading>
        <Flex justifyContent={'space-around'} flexWrap={'wrap'} flexDirection={'row'} >
            {locations.map(location => <LocationCard key={location.locationId} location={location} callbackClick={handleLocationClick}/> )}
        </Flex>

    </Flex>
}