import { extendTheme } from "@chakra-ui/react"

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
    colors: {
        brand: {
            primary: '#F9AA3E',
            text: '#070212'
        },
    },
})

export default theme;