import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createOrder } from '../../repository/orders'
import {getProfile, updateDisplayName, updatePassword} from "../../repository/user";

export interface IProfile {
    displayName: string,
    isLoading: boolean,
    error: boolean
}
const initialState: IProfile = {
    isLoading: false,
    displayName: '',
    error: false,
}

export const getProfileAction = createAsyncThunk(
    'profile/getProfile',
    async ( thunkAPI) => {
        const response = await getProfile();
        return response.data
    }
)

export const updatePasswordAction = createAsyncThunk(
    'profile/updatePassword',
    async (password: string, thunkAPI) => {
        const response = await updatePassword(password);
        return response.data
    }
)

export const updateDisplayNameAction = createAsyncThunk(
    'profile/updateDisplayName',
    async (displayName: string, thunkAPI) => {
        const response = await updateDisplayName(displayName);
        return response.data
    }
)
export const orderFormSlice = createSlice({
    name: 'orderForm',
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(getProfileAction.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(getProfileAction.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                state.displayName = action.payload.displayName
            })
            .addCase(getProfileAction.rejected, (state, action) => {
                state.isLoading = false
                state.error = true
            }).addCase(updatePasswordAction.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(updatePasswordAction.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false

            })
            .addCase(updatePasswordAction.rejected, (state, action) => {
                state.isLoading = false
                state.error = true
            }).addCase(updateDisplayNameAction.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(updateDisplayNameAction.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                state.displayName = action.payload.displayName
            })
            .addCase(updateDisplayNameAction.rejected, (state, action) => {
                state.isLoading = false
                state.error = true
            }),
})

export default orderFormSlice.reducer
