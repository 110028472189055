import ProfilePageView from "./ProfilePageView";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {RootState} from "../../store";
import {useEffect} from "react";
import {getProfileAction, updateDisplayNameAction, updatePasswordAction} from "./profileSlice";
import {useToast} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {getAccount, isAuth} from "../../services/auth";

export default () => {

    const profileState = useAppSelector((state: RootState) => state.profile);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const toast = useToast();
    useEffect(() => {
        dispatch(getProfileAction())
    }, []);
    const account = getAccount()

    const handleChangeOfDisplayName = async (displayName: string) => {
        try {
            (await dispatch(updateDisplayNameAction(displayName)).unwrap())
            toast({
                title: t('profileChange'),
                description:
                    t('displayNameChangeSuccess'),
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (e) {
            toast({
                title: t('profileChange'),
                description:
                    t('displayNameChangeError'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }

    }

    const handleChangeOfPassword = async (password: string) => {
        try {
            (await dispatch(updatePasswordAction(password)).unwrap())
            toast({
                title: t('profileChange'),
                description:
                    t('passwordChangeSuccess'),
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (e) {
            toast({
                title: t('profileChange'),
                description:
                    t('passwordChangeError'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }

    }
    return <ProfilePageView displayName={profileState.displayName} phone={account.phone} email={account.email} handleChangeOfPassword={handleChangeOfPassword} handleChangeOfDisplayName={handleChangeOfDisplayName}/>
}